<template>
  <page title="Sync Legacy Platform Account" :loading="loading">
    <template #body>
      <p class="disclaimer">
        <i class="far fa-info-circle"></i> Use this tool to sync an account from the legacy platform into the Platform2.
      </p>
      <p class="disclaimer danger">
        <i class="far fa-exclamation-triangle"></i> Changes in Platform2 may be overritten.
      </p>

      <div class="form">
        <div class="input-group size-sm">
          <dropdown :disabled="syncing" label="Account" :list="accountsList" v-model="selectedAccount"/>
        </div>
        <div class="input-group size-sm">
          <text-input type="number" :disabled="syncing" label="Legacy Account Id" v-model="legacyAccountId" v-if="selectedAccount.value === 'custom'"/>
        </div>
        <div class="input-group bottom">
          <form-button v-if="$checkPermission('platform.sync.sync')" :disabled="syncing" @click="sync" icon="fal fa-check" class="default">Sync</form-button>
        </div>
      </div>

      <template v-if="showOutput">
        <p class="output-label">Sync Script Output</p>
        <pre v-html="output" class="output" ref="output"></pre>
      </template>
    </template>
  </page>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'SyncView',

  components: {
    Dropdown,
    Page,
    FormButton,
    TextInput
  },

  methods: {
    sync () {
      this.output = ''
      this.showOutput = true
      this.syncing = true

      let accountValue

      if (this.selectedAccount.value === 'custom') {
        accountValue = this.legacyAccountId
      } else {
        accountValue = this.selectedAccount.value
      }

      tireviewliveApi.stream(`/sysadmin/platform/sync/${accountValue}`, undefined, undefined, (data, done) => {
        if (done) {
          this.syncing = false
        }

        this.output += data

        this.$nextTick().then(() => {
          this.$refs.output.scrollTop = this.$refs.output.scrollHeight
        })
      })
    }
  },

  data () {
    return {
      loading: true,
      output: '',
      selectedAccount: { label: '', value: '' },
      legacyAccountId: '',
      showOutput: false,
      syncing: false,
      accountsList: [{ label: 'Custom', value: 'custom' }]
    }
  },

  async mounted () {
    this.accountsList.push(...(await tireviewliveApi.get('/sysadmin/accounts')).map(item => {
      return {
        label: item.name,
        value: item.legacyAccountId
      }
    }).filter(i => i.value !== null))

    this.loading = false
  }

}
</script>

<style lang="less" scoped>
.disclaimer {
  padding: 10px;
  background-color: fade(@accent-color, 10%);
  border-radius: 5px;
  color: @accent-color;
  display: inline-block;
  margin-bottom: 20px;

  i {
    opacity: 0.6;
    margin-right: 10px;
  }

  &.danger {
    color: @danger-color;
    background-color: fade(@danger-color, 20%);
  }
}

.output-label {
  opacity: 0.5;
  margin: 30px 0 5px 15px;
}
.output {
  border: 2px solid #666;
  height: calc(100vh - 580px);
  padding: 20px;
  border-radius: 20px;
  color: lime;
  overflow: auto;
}
</style>
